import React from 'react'
import { format } from 'react-numberinput-formatter';
import { getComputations } from '../../../computed/recipeComputations';
import { RecipeRevision } from '../../../domain/types';
import { useTheme, TableRow, TableCell, Typography } from '@material-ui/core';
const pSBC = require('shade-blend-color').default

type ReturnType<T> = T extends (...args: any[]) => infer R ? R : T;

type ComputedRowProps<T = ReturnType<typeof getComputations>> = {
  label: string
  property: keyof T
  unit?: React.ReactNode
  isCurrency?: boolean
} & Intl.NumberFormatOptions

const rows: Array<ComputedRowProps> = [
  { property: 'wbf', label: 'WBF', maximumFractionDigits: 3 },
  { property: 'binderTotal', label: 'Totaal bindmiddel', unit: 'kg', maximumFractionDigits: 1, useGrouping: false },
  { property: 'volume', label: 'Uitlevering', unit: 'm3', maximumFractionDigits: 1, useGrouping: false },
  { property: 'density', label: 'Volumieke massa', unit: <span>kg/m<sup>3</sup></span>, maximumFractionDigits: 1, useGrouping: false },
  { property: 'percentageFine', label: 'Gehalte fijn', unit: 'L', maximumFractionDigits: 1 },
  { property: 'chloridePercentage', label: 'Chloridegehalte', unit: '%', maximumFractionDigits: 3 },
  { property: 'alkaliPercentage', label: 'Alkali gehalte', unit: '%', maximumFractionDigits: 3 },
  { property: 'predictedStrength', label: 'Voorspelde druksterkte', unit: <span>N/mm<sup>3</sup></span>, maximumFractionDigits: 1 },
  { property: 'basePrice', label: 'Basisprijs', isCurrency: true, minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true }
]

type ComputedTableRowsProps<T = ReturnType<typeof getComputations>> = {
  current: T,
  revisions: Array<RecipeRevision & { computations: T, diff: Partial<T> }>
  onlyDiff?: boolean
}

const ComputedTableRows: React.FC<ComputedTableRowsProps> = ({ current, revisions, onlyDiff }) => {
  const theme = useTheme()
  return <React.Fragment>
    {rows.filter(({ property }) => !onlyDiff || (revisions.filter(({ diff }) => typeof diff[property] !== 'undefined').length > 0)).map(({ property, label, isCurrency, unit, ...numberFormatProps }, k) => {
      const diffs = revisions.map(({ diff }, i) => {
        const v = diff[property]
        const v2 = current[property]
        return typeof v === 'undefined' || typeof v2 === 'undefined' ? 0 : v - v2
      }).sort((a, b) => a - b)
      const max = Math.max(...diffs)
      const min = Math.min(...diffs)
      const abs = max - min
      return <TableRow key={k}>
        <TableCell>{label}</TableCell>
        {revisions.map(({ computations, diff }, n) => {
          const v = diff[property]
          const v2 = current[property]
          const d = typeof v === 'undefined' || typeof v2 === 'undefined' ? 0 : v - v2
          const p = 1 / (abs || 1) * (abs ? abs - (d - min) : min > 0 ? 0 : 1)
          const background = d === 0 ? undefined : d > 0 ? pSBC(p, pSBC(0.5, theme.palette.success.light)) : pSBC(1 - p, pSBC(0.5, theme.palette.error.light))
          return <TableCell key={n} style={{ background }}>
            {isCurrency && <span> &euro;</span>}
            {format(computations[property] || 0, numberFormatProps)}
            {unit && <span> {unit}</span>} {d !== 0 && <Typography variant="caption" style={{ color: d > 0 ? theme.palette.success.dark : theme.palette.error.dark }}>({`${d > 0 ? '+' : ''}${format(d, numberFormatProps)}`})</Typography>}</TableCell>
        })}
        <TableCell>{isCurrency && <span> &euro;</span>}{format(current[property] || 0, numberFormatProps)}{unit && <span> {unit}</span>}</TableCell>
      </TableRow>
    })}
  </React.Fragment>
}

export default ComputedTableRows
