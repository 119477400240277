import React, {Fragment, useEffect, useState} from "react";
import {
  Button,
  Dialog, DialogActions,
  DialogTitle,
  Divider, Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {SieveTest, SieveTestStep} from "../../../types";
import {getHistory} from "../../../HTTPClients/RecipeApp/sieve_analysis/sieve-tests";

type renderSieveSteps = {
  sieveSteps: SieveTestStep[]
}
const RenderSieveSteps: React.FC<renderSieveSteps> = ({sieveSteps}) => {
  let steps=sieveSteps.filter(ss=>ss.restWeight>0)
  steps=steps.sort((a: SieveTestStep, b: SieveTestStep) => b.sieveSize.size - a.sieveSize.size)

  return (
    <TableCell>
      {steps.map((ss, index)=>
        <div className={'restweight'} key={'step-'+index}>
          {ss.restWeight} g (<span>{ss.sieveSize.code}</span>)
          {index<steps.length-1 && <span style={{marginRight:'.5rem'}}>,</span>}
        </div>
      )}
    )
    </TableCell>
  )
}

type sieveTestsHistory = {
  resource: any;
  onSelect: (sieveTest: SieveTest) => void,
  onClose: () => void
}
const SieveTestsHistory: React.FC<sieveTestsHistory> = ({resource, onSelect, onClose}) => {
  const [history, setHistory] = useState([])

  useEffect(() => {
    if(history.length) return
    getHistory(resource.id).then(
      function (response) {
        setHistory(response.data.data)
      }
    )
  },[history, setHistory, resource.id]);

  return (
    <Fragment>
      <Dialog open={true} maxWidth={'lg'} onClose={onClose} fullWidth={true}>
      <DialogTitle>Zeefanalyses historie</DialogTitle>
      <Divider />
      <div className={'resource-info'}>
        <span>Grondstof: <b>{resource.name}</b></span>
      </div>
      {history.length>0 && <div className={'sievetests-history dialog-section'}>
        <Table size="small">
        <TableHead>
          <TableRow key={'header'}>
            <TableCell style={{width:'10rem'}}>Omschrijving</TableCell>
            <TableCell style={{width:'10rem'}}>Zeefset</TableCell>
            <TableCell style={{width:'7rem'}}>Startgewicht</TableCell>
            <TableCell>Restgewichten</TableCell>
            <TableCell style={{width:'7rem'}}>Datum</TableCell>
            <TableCell style={{width:'12rem'}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((row) => (<TableRow key={'row'+row.id}>
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.sieveSet.code}</TableCell>
            <TableCell>{row.startingWeight} g</TableCell>
            <RenderSieveSteps sieveSteps={row.sieveSteps}></RenderSieveSteps>
            <TableCell>{row.changed}</TableCell>
            {(Boolean(resource.sieveTest) && resource.sieveTest.id===row.id)
              ? <TableCell><Icon>check</Icon><span className={'active'}> Actieve zeefanalyse</span></TableCell>
              : <TableCell><Button onClick={() => onSelect(row)} color="secondary">Opnieuw selecteren</Button></TableCell>
            }
          </TableRow>))}
        </TableBody>
        </Table>
      </div>}
      <Divider />
      <DialogActions style={{ position: 'relative' }}>
        <span style={{ flex: 1 }} />
        <Button onClick={onClose} color="secondary">Sluiten</Button>
      </DialogActions>
    </Dialog>
    </Fragment>
  )
}

export default SieveTestsHistory;
