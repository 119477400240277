import React, {useCallback, ChangeEvent, useState} from 'react';
import { FormControl, Select, Chip, MenuItem, Checkbox, ListItemText, makeStyles, Theme } from '@material-ui/core';
import {Plant} from "../domain/types";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
      horizontal: "left"
  },
  PaperProps: {
    style: { maxHeight: 342}
  },
  getContentAnchorEl: null,
  variant: 'menu'
};

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8.5px 0'
  },
  chip: {
    margin: 2,
  }
}));

export type plantsSelector = {
  plantsList: Plant[],
  plantsSelection: Plant[],
  onSelect: (selection: Plant[]) => void
}

const PlantsSelector: React.FC<plantsSelector> = ({plantsList, plantsSelection, onSelect}) => {
  const [plants]=useState(plantsList as Plant[])
  const [selection, setSelection]=useState(plantsSelection.length ? plantsSelection as Plant[] : plantsList as Plant[])
  const classes = useStyles()

  const handleSelect = useCallback((e: ChangeEvent<{ value: unknown }>) => {
    let selection=plants.filter(p => p.id && (e.target.value as unknown as number[]).indexOf(p.id) >= 0)
    if(selection.length>0) {
      setSelection(selection)
      onSelect(selection);
    }
  },[selection, plants, setSelection, onSelect]);

  return (
      <FormControl variant="standard">
        <Select
          labelWidth={0}
          multiple={true}
          value={selection.map(p => p.id )}
          onChange={handleSelect}
          inputProps={{id: 'select-multiple-plants',}}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as Number[]).map(id => {
                let plant=plants.find(p => p.id===id);
                return (
                  <Chip key={plant.id} label={plant && plant.name} className={classes.chip} />
                )
              })}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {plants.map(plant => (
            <MenuItem key={plant.id} value={plant.id} className={'header'}>
              <Checkbox checked={selection.map(p => p.id).indexOf(plant.id) > -1} />
              <ListItemText primary={plant.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}

export default PlantsSelector;
