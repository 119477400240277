import React, {ReactNode, useCallback, useState} from 'react';
import {calculateAdditions, Step} from './RecipeDialogContent';
import {Box, Button, Card, Icon, makeStyles, Slider, TextField, Typography} from '@material-ui/core';
import {Addition, Ingredient, ResourceType, ResourceUnion} from '../../domain/types';
import Dialog, {useDialog} from '../Dialog';
import ChangeIngredientDialogContent from './ChangeIngredientDialogContent';
import ToggleResourceOptionButtons from './ToggleResourceOptionButtons';
import AdditionsSlider from './AdditionsSlider';
import {grey} from '@material-ui/core/colors';
import ConfirmDialog, {ConfirmDialogProps} from "../ConfirmDialog";
import {getAll} from "../../HTTPClients/RecipeApp/resources/resources";
import {getGravels, getPercentageSand, getSands} from "../../computed/recipeComputations";

const useStyles = makeStyles({
  root: {
    zIndex: 100
  },
  track: {
    display: 'none'
  },
  sliderTitle: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginBottom: -16,
    padding: 16
  },
  sliderItem: {
    boxSizing: 'border-box',
    marginTop: -16, display: 'flex',
    flexDirection: 'column'
  },
  card: {
    background: grey[100],
    border: `1px solid ${grey[300]}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 106
  }
});

interface props {
  children?: ReactNode
}

const Placeholder: React.FC = ({ children }: props) => (
  <Box height="100%" width="50%" display="flex" justifyContent="center" alignItems="center"><Typography>{children}</Typography></Box>
)

const additionsStep: Step = {
  title: 'Toeslagen',
  hasErrors: recipe => false,
  isCompleted: recipe => recipe.ingredients.filter(r => r.resource.type === ResourceType.Addition).length > 0,
  optionalText: recipe => recipe.ingredients.filter(r => r.resource.type === ResourceType.Addition).map(r => r.resource.name).join(', '),
  StepComponent: ({ recipe, onChange }) => {
    const { ingredients } = recipe
    const [additions, setAdditions] = useState([] as Addition[])
    const [value, setValue] = useState(getPercentageSand(ingredients));
    const [inputValue, setInputValue] = useState(getPercentageSand(ingredients).toString());
    const filtered = ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & { resource: Addition }>
    const [hasSand, setHasSand] = useState(filtered.filter(r => r.resource.isSand).length > 0);
    const hasGravel = filtered.filter(r => !r.resource.isSand).length > 0;
    const { hide, getDialogProps } = useDialog();
    const { card, sliderTitle, sliderItem, ...sliderStyles } = useStyles();
    const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
    const [loading, setLoading] = useState(true)

    React.useEffect(() => {
      getAll().then(
          function(response) {
            setAdditions(response.data.data.filter((r:ResourceUnion) => r.type === ResourceType.Addition))
            setLoading(false)
          }
      )
    },[]); // No dependency, so runs only once...

    const groups = [
      {
        options: additions.filter(r => r.active && r.isSand && ingredients.map(i => i.resource.id).indexOf(r.id) < 0),
        loadingText: 'Zand laden',
        width: value
      },
      {
        options: additions.filter(r => r.active && !r.isSand && ingredients.map(i => i.resource.id).indexOf(r.id) < 0),
        loadingText: 'Grind laden',
        width: 100 - value
      }
    ];

    const handleChange = useCallback((additions?: Ingredient[]) => {
      if (additions) {
        additions.forEach(addition => {
          const index = ingredients.findIndex(r => r.resource.id === addition.resource.id);
          ingredients[index] = addition;
        });
      }

      calculateAdditions(ingredients, recipe.airPercentage, value);

      onChange({ ...recipe, ingredients });
      hide();
    }, [recipe, ingredients, value, hide, onChange]);

    const handleToggle = useCallback((addition: Addition) => {
      let index = ingredients.findIndex(i => i.resource.id === addition.id);
      let sands=[];
      let gravels=[];
      let updateGravels=false;
      let updateSands=false;

      if(index>=0) {
        confirmDelete({
          open: true,
          onCancel: () => confirmDelete({ open: false }),
          onConfirm: ()=> {
            ingredients.splice(index,1);

            sands  =getSands(ingredients);
            gravels=getGravels(ingredients);

            setHasSand(sands.length>0);
            if(addition.isSand) {
              if(!sands.length) {
                updateGravels=true;
              } else {
                updateSands=true;
              }
            } else {
              if(!gravels.length) {
                updateSands=true;
              } else {
                updateGravels=true;
              }
            }

            if(updateSands) {
              let percentage=sands.length > 0 ? 100 / sands.length : 100;
              sands.forEach(s => {
                const index = ingredients.findIndex(i => i.resource.id === s.resource.id);
                ingredients[index].percentage = percentage;
              });
            }

            if(updateGravels) {
              let percentage=gravels.length > 0 ? 100 / gravels.length : 100;
              gravels.forEach(g => {
                const index = ingredients.findIndex(i => i.resource.id === g.resource.id);
                ingredients[index].percentage = percentage;
              });
            }

            confirmDelete({ open: false })

            calculateAdditions(ingredients, recipe.airPercentage, value)
            onChange({ ...recipe, ingredients });
          }});
      } else {
        ingredients.push({resource: addition, amount: 0, percentage: 50} as Ingredient);

        sands  =getSands(ingredients);
        gravels=getGravels(ingredients);

        setHasSand(sands.length>0);
        if(addition.isSand) {
          if(sands.length===1 && gravels.length===1) {
            setValue(50);
            setInputValue('50');
          }
          updateSands=true;
        } else {
          if(sands.length===1 && gravels.length===1) {
            setValue(50);
            setInputValue('50');
          }
          updateGravels=true;
        }

        if(updateSands) {
          let percentage=sands.length > 0 ? 100 / sands.length : 100;
          sands.forEach(s => {
            const index = ingredients.findIndex(i => i.resource.id === s.resource.id);
            ingredients[index].percentage = percentage;
          });
        }

        if(updateGravels) {
          let percentage=gravels.length > 0 ? 100 / gravels.length : 100;
          gravels.forEach(g => {
            const index = ingredients.findIndex(i => i.resource.id === g.resource.id);
            ingredients[index].percentage = percentage;
          });
        }

        calculateAdditions(ingredients, recipe.airPercentage, value);
        onChange({ ...recipe, ingredients });
      }
    },[recipe, ingredients, value, setValue, onChange]);

    const inputValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      let test=parseInt(event.target.value);
      if(isNaN(test)) { setInputValue(''); return; }
      if(test>0 && test<100)
        setInputValue(test.toString());
    },[]);

    return (
      <Box marginBottom={2} marginTop={1}>
        <ToggleResourceOptionButtons loading={loading} groups={groups} onToggle={handleToggle as (option: ResourceUnion) => void}>
          <Card className={card} elevation={0}>
            <Box display="flex" width="100%" marginBottom={1}>
              {hasSand ?
                <div className={sliderTitle} style={{ width: (hasSand && hasGravel ? value : 50) + '%', paddingLeft: 24 }}>
                  <Typography variant="subtitle1">Zand</Typography>&nbsp;&nbsp;
                      {hasGravel &&
                        <div>
                          <TextField label={"%"} type={"number"} size={'small'} className={'slider-perc-input'} variant={'outlined'}
                              required={true} value={inputValue} inputProps={{ min: "0", max: "100" }}
                              onChange={inputValueChange}
                          />
                          <Button variant="contained" disabled={inputValue===''} className={'additionInput'} onClick={ () => {
                            let test=parseInt(inputValue);
                            if(!isNaN(test) && test>0 && test<100 && value!==test) {
                              setInputValue(test.toString());
                              setValue(test);
                              calculateAdditions(ingredients, recipe.airPercentage, test);
                              onChange({ ...recipe, ingredients });
                            }
                          }}><Icon>check</Icon></Button>
                        </div>
                      }
                </div> : <Box width="50%" />}
              {hasGravel ?
                <div className={sliderTitle} style={{ width: 100 - (hasSand && hasGravel ? value : 50) + '%', justifyContent: 'flex-end', paddingRight: 24, borderLeft: '2px dashed #ccc' }}>
                  <Typography variant="subtitle1">Grind</Typography>&nbsp;&nbsp;
                      {hasSand && <Typography color="primary">{Math.round(100 - value)}%</Typography>}
              </div> : <Box width="50%" />}
            </Box>
            <Slider
              classes={sliderStyles}
              value={(hasSand && hasGravel ? value : 50)}
              min={0}
              max={100}
              disabled={!hasSand || !hasGravel}
              onChange={(e, value) => { if(typeof value!='object') { setValue(value as number); setInputValue(value.toString()); } }}
              onChangeCommitted={() => { handleChange()  }}
            />
            <Box display="flex" width="100%">
              {hasSand ? <div className={sliderItem} style={{ width: (hasSand && hasGravel ? value : 50) + '%' }}>
                <AdditionsSlider
                  additions={filtered.filter(a => a.resource.isSand)}
                  onChange={handleChange}
                  onDelete={addition => handleToggle(addition.resource)}
                />
              </div> : <Placeholder>Geen zand gekozen</Placeholder>}
              {hasGravel ? <div className={sliderItem} style={{ width: 100 - (hasSand && hasGravel ? value : 50) + '%', borderLeft: '2px dashed #ccc' }}>
                <AdditionsSlider
                  additions={filtered.filter(a => !a.resource.isSand)}
                  onChange={handleChange}
                  onDelete={addition => handleToggle(addition.resource)}
                />
              </div> : <Placeholder>Geen grind gekozen</Placeholder>}
            </Box>
          </Card>
        </ToggleResourceOptionButtons>
        <ConfirmDialog {...dialogProps} title="Toeslag verwijderen" content="Weet u zeker dat u deze toeslag wilt verwijderen?" />
        <Dialog {...getDialogProps()} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
          {(props: any) => (
            <ChangeIngredientDialogContent
              {...props}
              onChange={handleChange}
            />
          )}
        </Dialog>
      </Box>
    )
  }
}

export default additionsStep;

