import { ResourceUnion, ResourceType, Addition, SieveTest, GradingCurve } from '../domain/types'
import { sanitizeGradingCurve } from '.';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (resource: ResourceUnion, removeId: boolean = false) {
  if (resource.type === ResourceType.Addition && (resource as Addition).sieveTest) {
    const { finenessModulus, ...sieveTest } = (resource as Addition).sieveTest as any;
    (resource as Addition).sieveTest = sieveTest as SieveTest;
    if ((resource as Addition).gradingCurve) {
      (resource as Addition).gradingCurve = sanitizeGradingCurve((resource as Addition).gradingCurve as GradingCurve, false)
    }
  }
  const { id, ...data } = resource
  return removeId ? data as ResourceUnion : resource
}
