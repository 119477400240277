import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Badge, Button, DialogActions, Divider, Grid, LinearProgress, TextField, Tabs, Tab, FormControlLabel,
  Switch, Dialog, DialogTitle, Avatar} from '@material-ui/core';
import {Alert} from "@material-ui/lab";

import {Resource, ResourcePlant, ResourceType} from '../../types';
import resourceTypes from "../../resourceTypes";
import {Settings} from "../../config/settings";
import {StoreContext} from "../../StoreContextProvider";
import useAuthorized from "../../useAuthorized";
import {emptyResourcePlant, getActiveResourcePlantIndex, validate, validateResourcePlants} from "./serviceFunctions";
import ConfirmDialog, {ConfirmDialogProps} from "../ConfirmDialog";
import EditResourcePlant from "./resourcePlant/EditResourcePlant";
import ExcipientProperties from './ExcipientProperties';
import PlantsSelection from "./PlantsSelection";

type ResourceDialogProps = {
  resources: any[],
  resource: Resource,
  resourcePlants: ResourcePlant[],
  onSave: (resource: Resource, resourcePlants: ResourcePlant[]) => Promise<void>,
  onCancel: () => void
}

const ResourceDialog: React.FC<ResourceDialogProps> = ({ resources, onSave, onCancel, ...props }) => {
  const disabled = !useAuthorized(['update:resources_info'])
  const firstInputRef = useRef<HTMLInputElement>(null)
  const {plants} = useContext(StoreContext)
  const [plantId, setPlantId] = useState(0)
  const [resource, setResource] = useState(props.resource as Resource)
  const [resourcePlants, setResourcePlants] = useState([] as ResourcePlant[])
  const [resourcePlant, setResourcePlant] = useState(emptyResourcePlant(0))
  const [changed, setChanged] = useState(false)
  const [isValid, setIsValid] = useState(validate(resource) && validateResourcePlants(resource.type, resourcePlants))
  const [isUnique, setIsUnique]= useState(true)
  const [scrollTop, setScrollTop] = useState(0)
  const [saving, setSaving] = useState(false)
  const [dialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)

  useEffect(()=> {
    setResourcePlants(props.resourcePlants)
  },[props.resourcePlants])

  useEffect(()=> {
    if(plantId) return;
    // First... try to preset the resource plant as set in settings
    setPlantId(Settings.default_plant_id)
  },[plantId])

  useEffect(()=>{
    if(!resourcePlants.length) return
    // Which resource plant can be edited?
    let index=getActiveResourcePlantIndex(plantId, Settings.default_plant_id, resourcePlants)
    if(index>-1) {
      // @ts-ignore
      setResourcePlant(resourcePlants[index])
    }
  },[plantId, resourcePlants])

  useEffect(()=>{
    firstInputRef.current && firstInputRef.current.focus()
  },[firstInputRef])

  // Plant related data tabset

  const tabPlantChange = (event: React.SyntheticEvent, changed: number) => {
    let index=resourcePlants.findIndex(rp => rp.plantId===changed)
    if(index===-1) return

    setPlantId(changed)
    // @ts-ignore
    setResourcePlant(resourcePlants[index])
  }

  const onInputChange = useCallback((changed: Resource) => {
    setResource(changed)
    setChanged(true)
    setIsValid(validate(changed) && validateResourcePlants(changed.type, resourcePlants))
  },[resourcePlants])

  const onPlantsChange = useCallback((changed: Resource) => {
    setResource(changed)

    // Rebuild resource plants based on the (new) plants selection
    let newResourcePlants=[] as ResourcePlant[]
    for(let plant of changed.plants) {
      let index=resourcePlants.findIndex(rp => rp.plantId===plant.id)
      if(index>-1)
        newResourcePlants.push(resourcePlants[index])
      else
        newResourcePlants.push(emptyResourcePlant(plant.id))
    }
    setResourcePlants(newResourcePlants)

    // Which resource plant can be edited?
    let index=getActiveResourcePlantIndex(plantId, Settings.default_plant_id, newResourcePlants)
    if(index>-1) {
        // @ts-ignore
        setResourcePlant(newResourcePlants[index])
    }

    // Update valid state
    setIsValid(validate(changed) && validateResourcePlants(changed.type, newResourcePlants))
  },[plantId]);

  const onResourcePlantChange = useCallback((changed: ResourcePlant) => {
    // Set plant ID when is/was empty resource plant object
    if(!changed.plantId)
      changed.plantId=plantId
    // @ts-ignore
    setResourcePlant(changed)

    // Rebuild resource plants array
    let newResourcePlants=[] as ResourcePlant[]
    for(let resourcePlant of resourcePlants)
      if(resourcePlant.plantId===changed.plantId)
        newResourcePlants.push(changed)
      else
        newResourcePlants.push(resourcePlant)
    setResourcePlants(newResourcePlants)

    // Update valid state
    setIsValid(validate(resource) && validateResourcePlants(resource.type, newResourcePlants))
  },[plantId, resource, resourcePlants]);

  const checkIsUnique = useCallback((code: any) => {
    if(!resources || !resource.articleCode) {
      setIsUnique(true)
      return
    }

    code=code.trim();
    if(!code) return true;

    for(let r of resources) {
      if(r.articleCode===null)
        continue;
      if(r.articleCode.toUpperCase()===code.toUpperCase() && r.id!==resource.id) {
        setIsUnique(false)
        return
      }
    }
    setIsUnique(true)
  },[resource, resources]);

  const handleCancelOrClose = useCallback(() => {
    if(changed) {
      confirmClose({
        open: true,
        onCancel: () => confirmClose({open: false}),
        onConfirm: () => {
          confirmClose({open: false});
          onCancel();
        }
      });
    } else
      onCancel();
  },[changed]);

  const handleSave = useCallback(async () => {
    setSaving(true)
    await onSave(resource, resourcePlants)
    setSaving(false)
  },[resource, resourcePlants])

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    e.stopPropagation() // Prevent event bubbling to scrollable parent
    setScrollTop(e.currentTarget.scrollTop);
  };

  // @ts-ignore
  return (
    <Fragment>
      <Dialog open={true} onClose={handleCancelOrClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true}>
        <DialogTitle style={{marginBottom:'.75rem'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar style={{background: resourceTypes[resource.type].color, marginRight: 8}}>
              <img src={resourceTypes[resource.type].icon} alt="" style={{width: 24, height: 24}}/>
            </Avatar>
            {!resource.id && <span>{resourceTypes[resource.type].title} toevoegen</span>}
            {resource.id && <span>Grondstof bewerken</span>}
          </div>
        </DialogTitle>
        <Divider />
        <div className={'resource'} style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }} onScroll={handleScroll}>
          {Boolean(resource.name) && scrollTop>50 &&
            <div className={'title-content resource-name'}>
              <b>{resource.name}</b>
            </div>
          }
          {scrollTop>390 &&
            <Badge className={'title-content plant-name'} badgeContent={plants[plants.findIndex(p=>p.id===plantId)].name+' gegevens'} color="primary" overlap={'rectangular'}></Badge>}
          <div className={'dialog-section'}>
            <h4>Grondstof informatie</h4>
            <Grid container={true} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Grondstof naam"
                  value={resource.name || ''}
                  onChange={e => onInputChange({ ...resource, name: e.target.value })}
                  onBlur={e=> resource.articleCode=resource.articleCode ? resource.articleCode : e.target.value}
                  variant="outlined"
                  fullWidth={true}
                  required={true}
                  inputRef={firstInputRef}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  label="Artikelcode"
                  value={resource.articleCode || ''}
                  onChange={e => { checkIsUnique(e.target.value); onInputChange({ ...resource, articleCode: e.target.value })}}
                  variant="outlined"
                  fullWidth={true}
                  required={true}
                  disabled={disabled}
                />
                {!isUnique && <Alert severity="warning">Geef svp een unieke artikel code op</Alert>}
              </Grid>
              {resource.type===ResourceType.Addition && !resource.id && <Grid item={true} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={resource.isSand || false}
                      onChange={e => onInputChange({ ...resource, isSand: e.target.checked })}
                      color="primary"
                      value="isSand"
                    />
                  }
                  label="Deze grondstof is een zand"
                />
              </Grid>}
            </Grid>
          </div>

          {resource.type===ResourceType.Excipient && <div className={'dialog-section next'}>
            <h4>Hulpstof eigenschappen</h4>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} style={{paddingBottom: '0'}}>
                  <ExcipientProperties resource={resource} onChange={onInputChange}></ExcipientProperties>
                </Grid>
            </Grid>
          </div>}

          <div className={'dialog-section next'}>
            <h4>Betoncentrale(s)</h4>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} style={{paddingBottom: '0'}}>
                <PlantsSelection resource={resource} plants={plants} onChange={onPlantsChange}></PlantsSelection>
              </Grid>
            </Grid>
          </div>

          {resource.plants.length>0 && plantId>0 &&
            <div className={'dialog-section'}>
              <h4>Grondstof gegevens per betoncentrale</h4>
              <Divider></Divider>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Tabs value={plantId} onChange={tabPlantChange} variant="scrollable" scrollButtons="auto">
                    {resource.plants.map(plant => (
                      <Tab label={plant.name} value={plant.id} key={'tab-'+plant.id} />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </div>}
            {resource.plants.length>0 &&
              <EditResourcePlant resource={resource} resourcePlant={resourcePlant} onChange={onResourcePlantChange}></EditResourcePlant>
            }
          <Divider />
          <DialogActions style={{ position: 'relative' }}>
            <span style={{ flex: 1 }} />
            <Button onClick={handleCancelOrClose} color="secondary">Annuleren</Button>
            <Button id={'save_resource'} onClick={handleSave} disabled={!isValid || !isUnique || saving} color="primary">Opslaan</Button>
            {saving && <LinearProgress variant="indeterminate" style={{ position: 'absolute', top: 0, left: -8, width: '100%', marginRight: -8 }} />}
          </DialogActions>
        </div>
        <ConfirmDialog {...dialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
      </Dialog>
    </Fragment>
  )
}

export default ResourceDialog;
