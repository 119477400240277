import React, { Fragment } from 'react';
import { Recipe, ResourceType, Addition } from '../../types';
import {Grid, ListItem, ListItemText, Card, ListItemSecondaryAction, List, Typography, Link, Theme } from '@material-ui/core';
import {
  getChloridePercentage,
  getAlkaliPercentage,
  getPredictedStrength,
  getBasePrice,
  getVolume,
  getBinderTotal,
  getRecipeConstraints,
  getPercentageFine,
  getDMax
} from '../../computed/recipeComputations';
import { makeStyles } from '@material-ui/core';
import { format } from 'react-numberinput-formatter';
import useAuthorized from '../../useAuthorized';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  secondaryAction: {
    position: 'static',
    top: 0,
    right: 0,
    paddingLeft: theme.spacing(2)
  }
}));

const SummaryListItem: React.FC<{ primary: React.ReactNode, secondary: React.ReactNode, defaultPlant: boolean, onClick?: () => void, error?: boolean }> = ({ primary, secondary, defaultPlant, error, onClick }) => {
  const { secondaryAction, ...classes } = useStyles();
  const canEdit = useAuthorized(['update:recipes'])
  return (
    <Grid item={true}>
      <List disablePadding={true}>
        <ListItem dense={true} classes={classes}>
          <ListItemText primary={primary} secondary={<Typography variant="body2" color={error ? 'error' : 'textSecondary'}>{secondary}</Typography>} />
          {canEdit && onClick && <ListItemSecondaryAction className={secondaryAction}>
            {defaultPlant && <Typography variant="subtitle2"><Link onClick={onClick}>Aanpassen</Link></Typography>}
          </ListItemSecondaryAction>}
        </ListItem>
      </List>
    </Grid>
  )
}

const getSummaryFields = (recipe: Recipe) => [
  { label: 'Sterkteklasse', value: recipe.strengthClass && recipe.strengthClass.code, step: 0, shouldRender: Boolean(recipe.strengthClass) },
  { label: 'Milieuklasse', value: (recipe.environmentClasses ? recipe.environmentClasses : []).map(ec => ec.code).join(', '), step: 1, shouldRender: recipe.environmentClasses && recipe.environmentClasses.length > 0 },
  { label: 'Consistentieklasse', value: recipe.consistencyClass && recipe.consistencyClass.code, step: recipe.strengthClass ? 2 : 0, shouldRender: Boolean(recipe.consistencyClass) },
  { label: 'Soort werk', value: recipe.typeOfWork && recipe.typeOfWork.code, step: 3, shouldRender: Boolean(recipe.typeOfWork) },
  { label: 'Chlorideklasse', value: recipe.chlorideClass && recipe.chlorideClass.code, step: 4, shouldRender: Boolean(recipe.chlorideClass) }
];

const getCalculatedFields = (recipe: Recipe) => {
  const { ingredients } = recipe;
  const { binderTotal, percentageFine, predictedStrength, chloridePercentage, alkaliPercentage } = getRecipeConstraints(recipe);
  return [
    { label: 'Volumieke massa', unit: <Fragment> kg/m<sup>3</sup></Fragment>, value: ingredients.reduce((sum, r) => sum += r.amount, 0), formatOptions: { maximumFractionDigits: 1 } },
    { label: 'Uitlevering', unit: ' L', value: getVolume(ingredients) + recipe.airPercentage * 10, formatOptions: { maximumFractionDigits: 1 } },
    { label: 'Totaal bindmiddel', unit: ' kg', formatOptions: { maximumFractionDigits: 1 }, ...binderTotal, value: getBinderTotal(ingredients, Boolean(recipe.attest)) },
    { label: 'Fijn materiaal', unit: ' L', formatOptions: { maximumFractionDigits: 1 }, ...percentageFine, value: getPercentageFine(ingredients, recipe) },
    { label: 'Berekende sterkte', unit: <Fragment> N/mm<sup>2</sup></Fragment>, formatOptions: { maximumFractionDigits: 1 }, ...predictedStrength, value: getPredictedStrength(ingredients, recipe.wbf || 0) },
    { label: 'Chloridegehalte', unit: '%', formatOptions: { maximumFractionDigits: 4 }, ...chloridePercentage, value: getChloridePercentage(ingredients) },
    { label: 'Alkali gehalte', unit: '%', formatOptions: { maximumFractionDigits: 4 }, ...alkaliPercentage, value: getAlkaliPercentage(ingredients) },
    { label: 'Basisprijs', pre: <Fragment>&euro;</Fragment>, value: getBasePrice(ingredients), formatOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 }, hideForExternal: true }
  ] as Array<{ label: string, unit?: React.ReactNode, pre?: React.ReactNode, value: number, formatOptions: any, hideForExternal?: boolean, min?: number, max?: number }>;
}

const DeliveryOverview: React.FC<{ recipe: Recipe, displayType: 'internal' | 'external', defaultPlant: boolean, goto: (step: number) => void }> = ({ recipe, goto, defaultPlant, displayType }) => {
  const { ingredients } = recipe;
  const additions = ingredients.filter(r => r.resource.type === ResourceType.Addition).sort((a, b) => (a.resource as Addition).isSand ? -1 : 0);
  const dMax = getDMax(additions as any);
  const recipeRestrictions = getRecipeConstraints(recipe);
  const canViewPrice = useAuthorized(['read:recipes_price'])

  return (
    <Fragment>
      {<Card style={{ marginBottom: 16 }}>
        <Grid container={true} justifyContent="space-between">
          {getSummaryFields(recipe).map(({ label, value, step, shouldRender }, k) => shouldRender ? (
            <SummaryListItem key={k}
                             primary={label}
                             secondary={value}
                             defaultPlant={defaultPlant}
                             onClick={() => goto(step)}
            />
          ) : null)}
          <Grid item={true}>
            <ListItem dense={true}>
              <ListItemText primary="Dmax" secondary={dMax} />
            </ListItem>
          </Grid>
          <SummaryListItem
            primary="Luchtgehalte"
            secondary={format(recipe.airPercentage) + '%'}
            defaultPlant={defaultPlant}
            onClick={() => goto(9)}
            error={(recipeRestrictions.airPercentage.max && recipe.airPercentage > recipeRestrictions.airPercentage.max) || (recipeRestrictions.airPercentage.min && recipe.airPercentage < recipeRestrictions.airPercentage.min) || false}
          />
        </Grid>
      </Card>}
      <Card>
        <Grid container={true} justifyContent="space-between">
          {getCalculatedFields(recipe).map(({ label, value, pre, unit, formatOptions, hideForExternal, min, max }, k) => (!hideForExternal || displayType === 'internal') && (label !== 'Basisprijs' || canViewPrice) ? (
            <SummaryListItem key={k}
                             primary={label}
                             secondary={<Fragment>{pre}{format(value, formatOptions)}{unit}</Fragment>}
                             defaultPlant={defaultPlant}
                             error={(min && value < min) || (max && value > max) || false}
            />
          ) : null)}
        </Grid>
      </Card>
    </Fragment>
  )
}

export default DeliveryOverview;
