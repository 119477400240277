import React, { useState, useCallback, Fragment, useEffect } from 'react';
import { DialogHeader } from '../Dialog';
import { DialogContent, DialogActions, Button, Typography, Slide, Dialog, DialogProps, InputAdornment } from '@material-ui/core';
import NumericTextField from '../NumericTextField';
import {TransitionProps} from "@material-ui/core/transitions/transition";

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props:any, ref) {
  return <Slide direction="up" {...props} />;
});
type EditField<T> = {
  label?: string
  initialValue?: number | string
  key: keyof T
  unit?: string
  maximumFractionDigits?: number
  helperText?: string
  renderCustomInput: (value: number | string, onChange: (value: number | string) => void) => JSX.Element
}

type EditFormDialogProps = DialogProps & {
  title: string
  fields: EditField<any>[]
  onSubmit: (values: any) => void
  dataValues?: any
  onClose?: () => void
}

const EditFormDialog: React.FC<EditFormDialogProps> = ({ title, onClose, fields, dataValues, onSubmit, ...props }) => {
  const [values, setValues] = useState(dataValues);
  const handleChange = useCallback((key: keyof any, value?: string | number) => {
    if(value===undefined) value='';
    setValues({...values, [key]: value})
  } , [values]);
  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(values);
  }, [onSubmit, values]);
  useEffect(() => {
    setValues({});
  }, []);
  useEffect(() => {
    if(dataValues!==undefined) setValues(dataValues)
  }, [dataValues]);

  return (
    <Dialog onClose={onClose} {...props} TransitionComponent={Transition as any} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <form onSubmit={handleSubmit} noValidate={true}>
        <DialogHeader onClose={() => onClose && onClose()}>{title}</DialogHeader>
        <DialogContent>
          {fields.map(({ label, initialValue, key, unit, maximumFractionDigits, helperText, renderCustomInput }, k) => renderCustomInput ?
            <Fragment key={k}>{renderCustomInput(values[key] ? values[key] : initialValue, value => handleChange(key, value))}</Fragment> :
            <NumericTextField key={k}
              label={label}
              value={typeof values[key] !== 'undefined' ? values[key] : initialValue}
              helperText={helperText}
              onChange={e => handleChange(key, e.target.value)}
              maximumFractionDigits={maximumFractionDigits}
              {...unit && {
                InputProps: {
                  endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
                }
              }}
              fullWidth={true}
              margin="normal"
              onFocus={(e: any) => e.currentTarget.select()}
            />)}
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit">Opslaan&nbsp;&nbsp;<Typography variant="caption">(ENTER)</Typography></Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditFormDialog;
