import React, {useCallback, useContext} from 'react';
import {ResourceUnion, ResourceType, ResourcePlant, Resource} from '../../types';
import {StoreContext} from "../../StoreContextProvider";
import {emptyResourcePlants} from "./serviceFunctions";
import ResourceDialog from "./ResourceDialog";

type NewResourceProps = {
  resources: ResourceUnion[];
  resourceType: ResourceType,
  onClose: () => void,
  onSave: (resource: Resource, resourcePlants: ResourcePlant[]) => Promise<void>
}

const NewResource: React.FC<NewResourceProps> = ({ resourceType, onClose, onSave, ...props }) => {
  const {plants} = useContext(StoreContext)
  const resources = props.resources;
  const resource = { type: resourceType, name: null, articleCode: null, isSand: null, remarks: null, dosingMethod: null,
    mainEffect: null, mainExcipientEffectId: null, secondaryEffect: null, secondaryExcipientEffectId: null, plants: plants }
  const resourcePlants=emptyResourcePlants(plants)

  const handleSave = useCallback(async (resource: Resource, resourcePlants: ResourcePlant[]) => {
    await onSave(resource, resourcePlants);
  },[])

  const handleClose = useCallback(() => onClose(),[])

  return (
    <ResourceDialog resources={resources} resource={resource} resourcePlants={resourcePlants}
      onSave={handleSave} onCancel={handleClose}>
    </ResourceDialog>
  )
}

export default NewResource;
