import axiosHttpClient from "../axiosHttpClient";
import {Settings} from "../../../config/settings";

export function post(data: any) {
	return axiosHttpClient.post('/resource/'+Settings.default_plant_id.toString(), data);
}

export async function toggleActive(id: number) {
	return axiosHttpClient.get('/resources/toggle-active/'+id.toString());
}

export async function remove(id: number) {
	return axiosHttpClient.delete('/resource/'+id.toString());
}

export async function getAll(active: boolean=null, plantIds: Number[]=[]) {
  let query=Settings.default_plant_id.toString()
  if(active!==null)
    query+='/'+ (active ? 'true' : 'false')
  let params=null
  if(plantIds.length)
    params['plantIds']=plantIds.join(',')

	return axiosHttpClient.get('/resources/'+query,{ params: params });
}

export async function getPlant(plant_id: number) {
  return axiosHttpClient.get('/resources/'+plant_id);
}
export async function getOne(id: number) {
	return axiosHttpClient.get('/resource/one/'+id.toString()+'/'+Settings.default_plant_id.toString());
}

export function deleteAllowed(id: number) {
	return axiosHttpClient.get('/resources/delete-allowed/'+id.toString());
}
