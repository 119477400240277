import {Addition, Cement, Excipient, Extra, Filler, ResourceType} from "../../types";

export function setPlantRelatedResources(recipe: any, resources: any[]) {
  // Set relevant plant related resource properties for this recipe
  for(let ingredient of recipe.ingredients) {
    let index=resources.findIndex(r => r.id===ingredient.resource.id);
    if(index<0)
      continue;

    ingredient.resource.price=resources[index].price;
    ingredient.resource.density=resources[index].density;
    ingredient.resource.alkaliPercentage=resources[index].alkaliPercentage;
    ingredient.resource.chloridePercentage=resources[index].chloridePercentage;
    ingredient.resource.cvalue=resources[index].cvalue;
    ingredient.resource.brand=resources[index].brand;
    ingredient.resource.supplier=resources[index].supplier;
    if(ingredient.resource.type===ResourceType.Addition) {
      (ingredient.resource as Addition).absorption=resources[index].absorption;
      (ingredient.resource as Addition).moisture=resources[index].moisture;
      (ingredient.resource as Addition).sieveTest=resources[index].sieveTest;
      (ingredient.resource as Addition).gradingCurve=resources[index].gradingCurve;
    }
    if(ingredient.resource.type===ResourceType.Cement) {
      (ingredient.resource as Cement).strengthWeek=resources[index].strengthWeek;
      (ingredient.resource as Cement).strengthNorm=resources[index].strengthNorm;
    }
    if(ingredient.resource.type===ResourceType.Filler) {
      (ingredient.resource as Filler).cementKFactors=resources[index].cementKFactors;
    }
    if(ingredient.resource.type===ResourceType.Extra) {
      (ingredient.resource as Extra).absorption=resources[index].absorption;
      (ingredient.resource as Extra).moisture=resources[index].moisture;
      (ingredient.resource as Extra).percentageFine=resources[index].percentageFine;
    }
    if(ingredient.resource.type===ResourceType.Excipient) {
      (ingredient.resource as Excipient).absorption=resources[index].absorption;
      (ingredient.resource as Excipient).moisture=resources[index].moisture;
      (ingredient.resource as Excipient).dosingMethod=resources[index].dosingMethod;
      (ingredient.resource as Excipient).mainEffect=resources[index].mainEffect;
      (ingredient.resource as Excipient).mainExcipientEffectId=resources[index].mainExcipientEffectId;
      (ingredient.resource as Excipient).secondaryEffect=resources[index].secondaryEffect;
      (ingredient.resource as Excipient).secondaryExcipientEffectId=resources[index].secondaryExcipientEffectId;
    }
  }
}
