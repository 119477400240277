import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Ingredient, Addition } from '../../domain/types';
import { Typography, Slider, makeStyles, IconButton, Icon, Theme } from '@material-ui/core';
import { getVolume } from '../../computed/recipeComputations';
import AdditionDetails from './AdditionDetails';

const useStyles = makeStyles((theme: Theme) => ({
  track: {
    display: 'none'
  },
  itemContainer: {
    display: 'flex',
    flex: 1
  },
  sliderItem: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: 16,
    paddingLeft: 24
  },
  addButton: {
    padding: 8,
    marginLeft: 9,
    color: theme.palette.grey[600]
  }
}));

type AdditionsSliderProps = {
  additions: Ingredient[],
  onChange: (additions: Array<Ingredient & { percentage: number }>) => void,
  onDelete: (addition: Ingredient & { resource: Addition }) => void
}

const AdditionsSlider: React.FC<AdditionsSliderProps> = ({ additions, onChange, onDelete }) => {
  const [percentages, setPercentages] = useState([] as number[]);
  const total = getVolume(additions);
  const { sliderItem, addButton, itemContainer, ...sliderStyles } = useStyles();

  useEffect(() => {
    const percentages = additions.slice(0, -1).map(a => Math.round(100 / total * getVolume([a])));
    setPercentages(percentages.map((p, k) => k > 0 ? p + percentages.reduce((sum, cur, i) => i < k ? sum + cur : sum, 0) : p));
  }, [additions, total]);

  const onPercentageChange = useCallback((percentages: number[]) => {
    const newAdditions = additions.map((addition, k) => {
      const previous = k > 0 ? percentages[k - 1] : 0;
      const percentage = ((percentages[k] ? percentages[k] : 100) - previous)
      const volume = total * percentage / 100;
      const amount = volume * addition.resource.density / 1000;
      return { ...addition, amount, percentage };
    });
    onChange(newAdditions);
  }, [onChange, additions, total]);

  return (
    <Fragment>
      <div className={itemContainer} style={{ minHeight: 69 }}>
        {additions.map((a, k) => (
          <div key={k} className={sliderItem} style={{ width: ((percentages[k] ? percentages[k] : 100) - (k > 0 ? percentages[k - 1] : 0)) + '%', position: 'relative', marginBottom: -16, borderLeft: k > 0 ? '1px solid #ccc' : '' }}>
            <Typography variant="subtitle2">{a.resource.name}</Typography>
            <IconButton onClick={() => onDelete(a as any)} style={{ position: 'absolute', top: 0, right: 0 }}><Icon fontSize="small">clear</Icon></IconButton>
          </div>
        ))}
      </div>
      <Slider
        classes={sliderStyles}
        value={percentages}
        min={0}
        max={100}
        onChange={(e, value) => { setPercentages(value as number[]) }}
        onChangeCommitted={() => onPercentageChange(percentages)}
      />
      <div className={itemContainer}>
        {additions.map((a, k) => (
          <AdditionDetails key={k}
            k={k}
            showInput={k<(additions.length-1)}
            addition={a as any}
            percentage={((percentages[k] ? percentages[k] : 100) - (k > 0 ? percentages[k - 1] : 0))}
            showPercentage={additions.length > 1}
            onChange={ (k, percentage)=>{
                if(k===0)
                  percentages[k]=percentage;
                else {
                  percentages[k]=percentages[k-1]+percentage;
                }
                setPercentages(percentages);
                onPercentageChange(percentages);
               }
            }
            style={{ borderLeft: k > 0 ? '1px solid #ccc' : '' }}
          />
        ))}
      </div>
    </Fragment>
  )
}

export default AdditionsSlider;
