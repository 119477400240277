import {Plant, ResourcePlant, ResourceType} from "../../types";

export function emptyResourcePlant(plant_id: number) {
  return {
    plantId: plant_id, brand: null, supplier: null, price: null, density: null, chloridePercentage: null,
    alkaliPercentage: null, cvalue: null, strengthWeek: null, strengthNorm: null, absorption: null, moisture: null,
    percentageFine: null, gradingCurve: null, sieveTest: null, cementKFactors: []
  }
}

export function emptyResourcePlants(plants: Plant[]) {
  let resourcePlants=[]
  for(let plant of plants)
    resourcePlants.push(emptyResourcePlant(plant.id))
  return resourcePlants
}

// Input validations
export function validate(resource: any) {
  // Validate common resource data
  if(!resource.plants.length) return false
  if(!Boolean(resource.name)) return false
  if(!Boolean(resource.articleCode)) return false
  if(resource.type===ResourceType.Excipient && !resource.mainExcipientEffectId) return false

  return true
}

export function validateResourcePlant(resourceType: ResourceType, resourcePlant: ResourcePlant) {
  // Validate plant specific resource data
  if(!Boolean(resourcePlant.price)) return false
  if(!Boolean(resourcePlant.density)) return false
  if(resourceType===ResourceType.Cement && !Boolean(resourcePlant.strengthNorm)) return false
  if(resourceType===ResourceType.Filler && !Boolean(resourcePlant.cementKFactors)) return false
  if(resourceType===ResourceType.Addition) {
    if(!Boolean(resourcePlant.sieveTest)) return false
    if(!Boolean(resourcePlant.sieveTest.startingWeight)) return false
    if(!resourcePlant.sieveTest.sieveSteps) return false
  }

  return true
}

export function validateResourcePlants(resourceType: ResourceType, resourcePlants: ResourcePlant[]) {
  if(!resourcePlants) return false
  for(let resourcePlant of resourcePlants) {
    if(!validateResourcePlant(resourceType, resourcePlant))
      return false
  }
  return true
}

export function getActiveResourcePlantIndex(plantId: number, defaultPlantId: number, resourcePlants: ResourcePlant[]) {
  if(!resourcePlants) return -1

  let index=resourcePlants.findIndex(rp => rp.plantId===plantId)
  if(index>-1) return index

  index=resourcePlants.findIndex(rp => rp.plantId===defaultPlantId)
  if(index>-1) return index

  return resourcePlants.length ? 0 : -1
}
