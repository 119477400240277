import React, {ChangeEvent, Fragment, useCallback, useEffect, useState} from "react";
import {Recipe, RecipeArticle} from "../../types";
import {
  addArticle,
  getArticles,
  getArticleSelectList,
  removeArticle
} from "../../HTTPClients/RecipeApp/recipes/recipes";
import {
  Badge,
  Button,
  Card, FormControl,
  Icon, InputAdornment,
  Link, MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField
} from "@material-ui/core";
import useAuthorized from "../../useAuthorized";
import ConfirmDialog, {ConfirmDialogProps} from "../ConfirmDialog";
import NumericTextField from "../NumericTextField";
import {format} from "react-numberinput-formatter";

const RecipeArticles: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  const [recipeArticles, setRecipeArticles] = useState([] as RecipeArticle[])
  const [articles, setArticles] = useState([])
  const [expanded, setExpanded] = useState(false)
  const canEdit = useAuthorized(['update:recipes'])
  const [editing, setEditing] = useState(false)
  const [articleId, setArticleId] = useState('')
  const [priceType, setPriceType] = useState('')
  const [amount, setAmount] = useState(0)
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

  useEffect(()=> {
    if(!recipe || !recipe.id) return
    getArticles(recipe.id).then(
      function(response) {
        let articles=[]
        for(let row of response.data.data)
          articles.push(
            { id: row.id, articleId: row.article.id, name: row.article.name, priceType: row.article.price_type_code, amount: row.amount })
        setRecipeArticles(articles)
      })
  },[recipe, setRecipeArticles])

  useEffect(()=> {
    if(articles.length) return
    getArticleSelectList().then(
      function(response) {
        setArticles(response.data.data)
      })
  },[articles,setArticles])

  const amountChange = useCallback((amount: number) => {
    setAmount(amount);
  },[setAmount]);

  const articleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setArticleId(value);
    // Set price type
    let index=articles.findIndex( a => a.id===parseInt(value))
    setPriceType(articles[index].price_type_code)
  },[articles, setArticleId, setPriceType]);

  const onAdd= useCallback(() => {
    addArticle({ recipes_id: recipe.id, articles_id: articleId, amount: amount }).then(
      function(response) {
        getArticles(recipe.id).then(
          function(response) {
            let articles=[]
            for(let row of response.data.data)
              articles.push(
                { id: row.id, articleId: row.article.id, name: row.article.name, priceType: row.article.price_type_code, amount: row.amount })
            setRecipeArticles(articles)
            setArticleId('')
            setAmount(0)
          })
      })
  },[recipe, articleId, amount, setRecipeArticles, setArticleId, setAmount])

  const onDelete = useCallback((id: number) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: () => {
        let index=recipeArticles.findIndex(ra => ra.id===id)
        if(index===-1) return
        recipeArticles.splice(index,1);

        confirmDelete({ open: false });

        removeArticle(id).then(
          function(response) {
            getArticles(recipe.id).then(
              function(response) {
                let articles=[]
                for(let row of response.data.data)
                  articles.push(
                    { id: row.id, articleId: row.article.id, name: row.article.name, priceType: row.article.price_type_code, amount: row.amount })
                setRecipeArticles(articles)
              })
          }
        )
      }
    });
  },[recipeArticles, recipe]);

  const onEndEditing = useCallback(() => {
    setEditing(false);
    if(!recipeArticles.length)
      setExpanded(false)
    setArticleId('')
    setAmount(0)
  },[recipeArticles, setEditing, setExpanded]);

  return (
    <Fragment>
    <Card className={'no-print'} style={{ marginBottom: 16, marginTop: 16 }}>
      <div style={{padding: '18px', paddingTop: '18px', paddingBottom: expanded ? '0px' : '12px'}}>
        {recipeArticles.length>0 && <Badge badgeContent={recipeArticles.length} color={'secondary'} overlap={'rectangular'} anchorOrigin={{
          vertical: 'top', horizontal: 'right',
        }}>
          <Button variant={'outlined'} color={'primary'} disabled={!recipeArticles.length} onClick={(e)=> { setEditing(false); setExpanded(!expanded)} }>
            Recept artikelen {expanded ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
          </Button>
        </Badge>}
        {recipeArticles.length===0 && <span><b>Recept artikelen</b> (leeg)</span>}
        {canEdit && !editing && <Link style={{marginLeft: '3rem'}} onClick={() => { setExpanded(true); setEditing(true)} }>Aanpassen</Link>}
        {editing && <Link style={{marginLeft: '3rem'}} onClick={() => onEndEditing()}>Aanpassen beeindigen</Link>}
      </div>
      {expanded && <Table style={{width: 'auto'}}>
        <TableHead>
          <TableRow>
            <TableCell style={{width: '400px'}}>Artikel</TableCell>
            <TableCell style={{width: '100px',textAlign: 'right'}}>Hoeveelheid</TableCell>
            <TableCell style={{width: '55px'}}>Eenheid</TableCell>
            {editing && <TableCell style={{width: '100px'}}></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {recipeArticles.map((recipeArticle: RecipeArticle, k) =>
            <TableRow key={recipeArticle.id}>
              <TableCell>{recipeArticle.name}</TableCell>
              <TableCell style={{textAlign: 'right'}}>{format(recipeArticle.amount,{ maximumFractionDigits: 3 })}</TableCell>
              <TableCell>{recipeArticle.priceType}</TableCell>
              {editing && <TableCell>
                <Button variant={'text'} onClick={()=>onDelete(recipeArticle.id)}><Icon>delete</Icon> Verwijderen...</Button>
              </TableCell>}
            </TableRow>
          )}
          {editing && <TableRow key={'-1'}>
            <TableCell>
              <FormControl size={'small'} style={{width: '100%'}}>
                <Select variant={'outlined'} value={articleId} style={{width: '100%'}} onChange={articleChange}>
                  {articles.map((article, index) =>
                    recipeArticles.findIndex(ra => ra.articleId===article.id) ===-1 &&
                    <MenuItem key={index} value={article.id}>{article.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell style={{textAlign: 'right'}}>
              <NumericTextField value={amount || ''} onChange={(e: any) => amountChange(e.target.value)}
                margin="dense" fullWidth={true} required={true} maximumFractionDigits={2} />
            </TableCell>
            <TableCell>{priceType}</TableCell>
            <TableCell>
              <Button variant={'outlined'} color={'primary'} disabled={!articleId || !amount} onClick={()=>onAdd()}>Toevoegen</Button>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>}
    </Card>
    <ConfirmDialog {...dialogProps} title="Recept artikel verwijderen" content="Weet u zeker dat u dit recept artikel wil verwijderen?" />
    </Fragment>
  )
}

export default RecipeArticles;
