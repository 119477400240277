import React, { useEffect, useCallback, useState } from 'react'
import {ResourceType, Cement, ResourcePlant} from '../../../types'
import {Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core'
import NumericTextField from '../../NumericTextField'
import useAuthorized from '../../../useAuthorized'
import {getAll} from '../../../HTTPClients/RecipeApp/resources/resources';

export type FillerPropertiesProps = {
  resourcePlant: ResourcePlant,
  onResourcePlantChange: (resource_plant: ResourcePlant) => void
}

const FillerProperties: React.FC<FillerPropertiesProps> = ({ resourcePlant, onResourcePlantChange }) => {
  const disabled = !useAuthorized(['update:resources_filler'])
  const [cements, setCement] = useState([] as Cement[])

  useEffect(() => {
    if(cements.length) return;
    getAll(true).then(
        function(response) {
          setCement(response.data.data.filter((r: any) => r.type===ResourceType.Cement))
        }
    )
  },[cements, setCement])

  useEffect(() => {
    const missing = cements.reduce((missing, cement) => {
      if(typeof resourcePlant.cementKFactors === 'undefined') {
        return [...missing, cement]
      } else if (resourcePlant.cementKFactors.map(c => c.cement.id).indexOf(cement.id) < 0) {
        return [...missing, cement]
      } else {
        return missing
      }
    }, [] as Cement[])
    if (missing.length > 0) {
      onResourcePlantChange({ ...resourcePlant, cementKFactors: [...resourcePlant.cementKFactors || [],
        ...missing.map(m => ({ cement: m, kFactor: 0 }))] })
    }
  }, [cements, resourcePlant, onResourcePlantChange]);

  const handleChange = useCallback((cement: Cement, kFactor: number) => {
    const index = resourcePlant.cementKFactors.findIndex(item => item.cement.id === cement.id)
    resourcePlant.cementKFactors[index].kFactor = kFactor
    onResourcePlantChange({ ...resourcePlant, cementKFactors: [...resourcePlant.cementKFactors] })
  }, [onResourcePlantChange, resourcePlant])

  return (
      <Table>
        <TableHead>
          <TableRow><TableCell>Cement</TableCell><TableCell>K-Factor</TableCell></TableRow>
        </TableHead>
        <TableBody>
          {resourcePlant.cementKFactors.length>0 && resourcePlant.cementKFactors.map(({ cement, kFactor }, k) => (
            <TableRow key={k}>
              <TableCell>{cement.name}</TableCell>
              <TableCell padding="none">
                <NumericTextField
                  margin="dense"
                  value={kFactor}
                  onChange={(e: any) => handleChange(cement, e.target.value)}
                  maximumFractionDigits={2}
                  disabled={disabled}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  )
}

export default FillerProperties
