import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Step} from './RecipeDialogContent'
import {Box, FormControlLabel, Icon, Typography} from '@material-ui/core'
import NumericTextField from '../NumericTextField'
import {getBinderTotal, getKFactor, getMinCement} from '../../computed/recipeComputations'
import {Filler, Ingredient, ResourceType, ResourceUnion} from '../../domain/types'
import {format, HTMLNumericInputElement} from 'react-numberinput-formatter'
import {useDialog} from '../Dialog'
import GreenSwitch from '../GreenSwitch'
import OptionEditButton from './OptionEditButton'
import OptionButtonsContainer from './OptionButtonsContainer'
import ToggleResourceOptionButtons from './ToggleResourceOptionButtons'
import EditFormDialog from './EditFormDialog'
import ConfirmDialog, {ConfirmDialogProps} from "../ConfirmDialog";
import {getAll} from "../../HTTPClients/RecipeApp/resources/resources";

const editFillerFormFields = [
  {
    label: 'Hoeveelheid',
    key: 'amount',
    unit: 'Kg',
    maximumFractionDigits: 1
  },
  {
    label: 'K-Factor',
    key: 'kFactor',
    maximumFractionDigits: 2
  },
  {
    label: 'Maximum K-Factor (%)',
    key: 'attestPercentage',
    maximumFractionDigits: 2,
    helperText: 'Het bindmiddelfactor (K-Factor) beperkende percentage'
  }
]

const editCementAmount = [
  {
    label: 'Hoeveelheid',
    key: 'amount',
    unit: 'Kg',
    maximumFractionDigits: 1
  }
]

const editCementPercentage = [
  {
    label: 'Percentage',
    key: 'percentage',
    unit: '%',
    maximumFractionDigits: 1
  }
]

const bindersMixtureStep: Step = {
  title: 'Bindmiddelen',
  hasErrors: recipe => false,
  isCompleted: recipe => recipe.ingredients.filter(r => [ResourceType.Cement, ResourceType.Filler].indexOf(r.resource.type) >= 0).length > 0,
  optionalText: recipe => recipe.ingredients.filter(r => [ResourceType.Cement, ResourceType.Filler].indexOf(r.resource.type) >= 0).map(r => r.resource.name).join(', '),
  StepComponent: ({ recipe, onChange }) => {
    const { ingredients } = recipe
    const [resources, setResources] = useState([] as ResourceUnion[])
    const initialTotal = ingredients.filter(r => r.resource.type === ResourceType.Cement).reduce((total, cement) => total += cement.amount, 0)
    const [totalCement, setTotalCement] = useState(initialTotal || getMinCement(recipe.environmentClasses || []) as number | undefined)
    const water = ingredients.find(r => r.resource.type === ResourceType.Water)
    const { show, hide, getDialogProps } = useDialog()
    const [loading, setLoading]=useState(true)

    useEffect(() => {
      if(resources.length) return
      getAll().then(
        function(response) {
          setResources(response.data.data);
          setLoading(false);
        }
      )
    },[resources])

    const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

    const groups = [
      {
        options: resources.filter(r => r.active && r.type === ResourceType.Cement && ingredients.map(i => i.resource.id).indexOf(r.id) < 0),
        loadingText: 'Cementen laden'
      },
      {
        options: resources.filter(r => r.active && r.type === ResourceType.Filler && ingredients.map(i => i.resource.id).indexOf(r.id) < 0),
        loadingText: 'Vulstoffen laden'
      }
    ]

    const selectedCementGroupContent = (<span></span>)

    const selectedGroups = [
      {
        options: ingredients.filter(r => r.resource.type === ResourceType.Cement),
        loadingText: 'Cementen laden',
        noOptionsText: 'Geen cementen gekozen',
        content: selectedCementGroupContent
      },
      {
        options: ingredients.filter(r => r.resource.type === ResourceType.Filler),
        loadingText: 'Vulstoffen laden',
        noOptionsText: 'Geen vulstoffen gekozen'
      }
    ]

    const handlePercentageChange = useCallback((ingredient: Ingredient) => {
      const index = ingredients.findIndex(r => r.resource.id === ingredient.resource.id)
      index >= 0 && (ingredients[index] = ingredient)
      const cements = ingredients.filter(r => r.resource.type === ResourceType.Cement)
      if (cements.length === 2 && ingredient.percentage) {
        const index = ingredients.findIndex(r => r.resource.type === ResourceType.Cement && r.resource.id !== ingredient.resource.id)
        ingredients[index] = { ...ingredients[index], percentage: 100 - ingredient.percentage }
      }
      let totalCement = cements.reduce((total, cement) => total += cement.amount, 0)
      setTotalCement(totalCement)
      ingredients.forEach((r, k) => r.resource.type === ResourceType.Cement && (ingredients[k] = { ...r, amount: (totalCement || 0) * (r.percentage || 0) / 100 }))
      totalCement = cements.reduce((total, cement) => total += cement.amount, 0)
      setTotalCement(totalCement)
      calculateChanges(ingredients, recipe.wbf, Boolean(recipe.attest))
      onChange({ ...recipe, ingredients })
      hide()
    }, [recipe, ingredients, hide, onChange])

    const handleChange = useCallback((ingredient: Ingredient) => {
      const index = ingredients.findIndex(r => r.resource.id === ingredient.resource.id)
      index >= 0 && (ingredients[index] = ingredient)
      const totalCement = ingredients.filter(r => r.resource.type === ResourceType.Cement).reduce((total, cement) => total += cement.amount, 0)
      setTotalCement(totalCement)
      ingredients.forEach((r, k) => r.resource.type === ResourceType.Cement && (ingredients[k] = { ...r, percentage: 100 / totalCement * r.amount }))
      calculateChanges(ingredients, recipe.wbf, Boolean(recipe.attest), ingredient.resource.type === ResourceType.Filler)
      onChange({ ...recipe, ingredients: [...ingredients] })
      hide()
    }, [recipe, ingredients, hide, onChange])

    const handleToggle = useCallback((resource: ResourceUnion) => {
      const index = ingredients.findIndex(r => r.resource.id === resource.id)
      if(index >= 0) {
        confirmDelete({
          open: true,
          onCancel: () => confirmDelete({ open: false }),
          onConfirm: ()=> {
            ingredients.splice(index, 1)
            if(resource.type===ResourceType.Cement) {
              const numCement = recipe.ingredients.filter(r => r.resource.type === ResourceType.Cement).length
              ingredients.forEach((r, k) => r.resource.type === ResourceType.Cement && (ingredients[k] = {
                ...r,
                amount: (totalCement || 0) / numCement,
                percentage: 100 / numCement
              }))
            }
            calculateChanges(ingredients, recipe.wbf, Boolean(recipe.attest))
            onChange({ ...recipe, ingredients })
            confirmDelete({ open: false })
          }});
      } else {
        ingredients.push({ resource, amount: 0 } as Ingredient)
        if(resource.type===ResourceType.Cement) {
          let numCement = recipe.ingredients.filter(r => r.resource.type === ResourceType.Cement).length;
          ingredients.forEach((r, k) => r.resource.type === ResourceType.Cement && (ingredients[k] = {
            ...r,
            amount: (totalCement || 0) / numCement,
            percentage: 100 / numCement
          }))
        }
        calculateChanges(ingredients, recipe.wbf, Boolean(recipe.attest))
        onChange({ ...recipe, ingredients })
      }
    }, [recipe, ingredients, totalCement, onChange])

    const handleWaterChange = useCallback((e: React.ChangeEvent<HTMLNumericInputElement>) => {
      const amount = (e.target.value || 0)
      const index = ingredients.findIndex(r => r.resource.type === ResourceType.Water)
      index >= 0 && (ingredients[index] = { ...ingredients[index], amount })

      // REC-36: Problemen bij water berekening in recepten
      let wbf=recipe.wbf;
      let binderTotal=getBinderTotal(ingredients, Boolean(recipe.attest));
      if(binderTotal)
        wbf = amount / binderTotal;

      onChange({ ...recipe, ingredients: [...ingredients], wbf })
    }, [recipe, ingredients, onChange])

    const handleWbfChange = useCallback((e: React.ChangeEvent<HTMLNumericInputElement>) => {
      const wbf = (e.target.value || 0)
      const index = ingredients.findIndex(r => r.resource.type === ResourceType.Water)

      // Set recipe wbf value and recalculate water amount
      recipe.wbf=wbf;
      index >= 0 && (ingredients[index] = { ...ingredients[index], amount: getBinderTotal(ingredients, Boolean(recipe.attest)) * wbf })
      onChange({ ...recipe, ingredients: [...ingredients], wbf })
    }, [onChange, ingredients, recipe])

    const handleTotalCementChange = useCallback((e: React.ChangeEvent<HTMLNumericInputElement>) => {
      setTotalCement(e.target.value)
      ingredients.forEach((r, k) => r.resource.type === ResourceType.Cement && (ingredients[k] = { ...r, amount: (e.target.value || 0) * (r.percentage || 0) / 100 }))
      calculateChanges(ingredients, recipe.wbf, Boolean(recipe.attest))
      onChange({ ...recipe, ingredients })
    }, [onChange, ingredients, recipe])

    const handleEdit = useCallback((ingredient: Ingredient, editPercentage: boolean=false) => {
      const fields = (ingredient.resource.type === ResourceType.Cement ? editPercentage ?
        editCementPercentage : editCementAmount : recipe.attest ? [...editFillerFormFields,
      {
        label: 'Attest percentage',
        key: 'attestPercentage',
        maximumFractionDigits: 2
      }] : editFillerFormFields).map(field => ({
        ...field,
        initialValue: ingredient[field.key as keyof Ingredient]
      }))
      const onSubmit = (values: any) => {
        if(ingredient.resource.type === ResourceType.Cement && editPercentage) {
          if(validPercentage(values.percentage))
            handlePercentageChange({ ...ingredient, ...values })
        } else {
          if(validAmount(values.amount) || (values.hasOwnProperty('kFactor') && validKFactor(values.kFactor)))
            handleChange({ ...ingredient, ...values })
        }
      }
      if(ingredient.resource.type === ResourceType.Cement)
        show({ fields, title: ingredient.resource.name, dataValues: editPercentage ? { percentage: ingredient.percentage } : { amount: ingredient.amount }, onSubmit })
      else
        show({ fields, title: ingredient.resource.name, dataValues: { amount: ingredient.amount, kFactor: ingredient.kFactor }, onSubmit })
    }, [ingredients, show, validPercentage, handleChange, handlePercentageChange, recipe.attest])

    function validPercentage(percentage: number) {
      let count=recipe.ingredients.filter(r => r.resource.type === ResourceType.Cement).length;
      return !(percentage < 1 || ((count === 1 && percentage > 100) || (count > 1 && percentage > (101 - count))));
    }
    function validAmount(amount) {
      return amount>0;
    }
    function validKFactor(kFactor) {
      return kFactor>.01 && kFactor<100;
    }

    const getOptionContent = useCallback((ingredient: Ingredient) => (
      <OptionEditButton
        onClick={() => handleToggle(ingredient.resource)}
        onEdit={() => handleEdit(ingredient,true)}
        showIcon={ingredient.resource.type === ResourceType.Filler}
        editContent={
          ingredient.resource.type === ResourceType.Filler ? (
            <Fragment>
              <Typography variant="subtitle2">K {format(ingredient.kFactor || 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>&nbsp;&nbsp;<Icon fontSize="small">create</Icon>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Typography variant="subtitle2">{format(ingredient.amount, { maximumFractionDigits: 1 })} kg</Typography>
            </Fragment>
          ) :
            <Fragment>
              <Typography variant="subtitle2">{format(ingredient.percentage, { maximumFractionDigits: 1 })}% <Icon color="inherit" fontSize="small" style={{ marginLeft: 4, paddingTop: 2 }}>edit</Icon></Typography>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a onClick={ (e)=>{ handleEdit(ingredient,false); e.stopPropagation() }} style={{ color: '#0091ea' }}>
                <Typography variant="subtitle2">{format(ingredient.amount, { maximumFractionDigits: 1 })} kg <Icon color="inherit" fontSize="small" style={{ marginLeft: 4, paddingTop: 2 }}>create</Icon></Typography>
              </a>
            </Fragment>
        }
      >
        {ingredient.resource.name}
      </OptionEditButton>
    ), [handleEdit, handleToggle])

    return (
      <Box marginBottom={2} marginTop={1}>
        <Box marginBottom={3} display="flex" alignItems="center">
          <NumericTextField
            label="WCF"
            value={typeof recipe.wbf !== 'undefined' ? recipe.wbf : 0}
            onChange={handleWbfChange}
            maximumFractionDigits={3}
            style={{ marginRight: 8 }}
          />
          <NumericTextField
            label="Totaal cement"
            value={totalCement}
            onChange={handleTotalCementChange}
            maximumFractionDigits={1}
            style={{ marginRight: 8 }}
          />
          <NumericTextField
            label="Water"
            value={water && water.amount}
            onChange={handleWaterChange}
            maximumFractionDigits={1}
          />
          <Box marginLeft={2}>
            <FormControlLabel
              control={<GreenSwitch checked={recipe.allowRecycleWater || false} onChange={() => onChange({ ...recipe, allowRecycleWater: !recipe.allowRecycleWater })} />}
              label={<Typography variant="body2">Recycle water toestaan</Typography>}
            />
          </Box>
        </Box>
        <ToggleResourceOptionButtons loading={loading} groups={groups} onToggle={handleToggle}>
          <OptionButtonsContainer
            loading={loading}
            groups={selectedGroups.map(group => ({ ...group, getOptionContent }))}
          />
        </ToggleResourceOptionButtons>
        <EditFormDialog title="" children={null} fields={[]} onSubmit={() => ({})} {...getDialogProps()} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')} />
        <ConfirmDialog {...dialogProps} title="Bindmiddel verwijderen" content="Weet u zeker dat u dit bindmiddel wilt verwijderen?" />
      </Box>
    )
  }
}

export default bindersMixtureStep

export function calculateChanges(ingredients: Ingredient[], wbf?: number, hasAttest?: boolean, noKFactorCalc?: boolean) {
  !noKFactorCalc && ingredients.forEach((r, index) => {
    if (r.resource.type === ResourceType.Filler && (r.resource as Filler).cementKFactors) {
      ingredients[index] = { ...r, kFactor: hasAttest ? 1 : getKFactor(r.resource as any, ingredients.filter(r => r.resource.type === ResourceType.Cement)) }
    }
  })
  const wIndex = ingredients.findIndex(r => r.resource.type === ResourceType.Water)
  wIndex >= 0 && wbf && (ingredients[wIndex] = { ...ingredients[wIndex], amount: getBinderTotal(ingredients, hasAttest || false) * wbf })
}
