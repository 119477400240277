import React, {useCallback, useEffect, useState} from 'react';
import AddResourceSteps from './ResourceDialog';
import {Resource, ResourcePlant, ResourceUnion} from "../../types";
import {get as getResourcePlants} from "../../HTTPClients/RecipeApp/resources/resourcePlants";

export type EditResourceProps = {
  resource: Resource,
  resources: ResourceUnion[],
  onClose: () => void
  onSave: (resource: Resource, resourcePlants: ResourcePlant[]) => Promise<void>
}

const EditResource: React.FC<EditResourceProps> = ({ resource, resources, onClose, onSave }) => {
  const [resourcePlants, setResourcePlants] = useState([] as ResourcePlant[])
  const [loaded, setLoaded] = useState(false)

  if(resource.articleCode===null && resource.name)
    resource.articleCode=resource.name

  useEffect(()=> {
    if(loaded) return
    getResourcePlants(resource.id).then(
      function(response) {
        setResourcePlants(response.data.data)
        setLoaded(true)
      }
    )
  },[loaded])

  const handleSave = useCallback(async (resource: Resource, resourcePlants: ResourcePlant[]) => {
    await onSave(resource, resourcePlants)
  },[resource, resourcePlants])

  const handleClose = useCallback(() => onClose(),[])

  return (
    <AddResourceSteps resources={resources} resource={resource} resourcePlants={resourcePlants}
      onSave={handleSave} onCancel={handleClose}>
    </AddResourceSteps>
  )
}

export default EditResource;
