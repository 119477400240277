import React from 'react';
import { GradingCurve } from '../../domain/types';
import { LineChart, XAxis, Label, CartesianGrid, Tooltip, Legend, Line, YAxis } from 'recharts';
import { blue, green, red } from '@material-ui/core/colors';

const commonLineProps = {
  connectNulls: true,
  type: 'linear' as 'linear',
  activeDot: { r: 8 }
}

const lines = [
  {
    yAxisId: 'right',
    dataKey: 'rest',
    name: 'Zeefrest',
    stroke: green[700],
    ...commonLineProps
  },
  {
    yAxisId: 'right',
    dataKey: 'min',
    name: 'A',
    stroke: blue[500],
    ...commonLineProps
  },
  {
    yAxisId: 'right',
    dataKey: 'maxAlt',
    name: 'B',
    stroke: blue[900],
    ...commonLineProps
  },
  {
    yAxisId: 'right',
    dataKey: 'max',
    name: 'C',
    stroke: red[500],
    ...commonLineProps
  }
];


const commonYAxisProps = {
  ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tick: { fontSize: 13 }
}

const commonYAxisLabelProps = {
  fontSize: 14,
  angle: -90
}

const yAxis = [
  {
    orientation: 'left' as 'left',
    yAxisId: 'left',
    label: {
      value: 'Doorval in (%)',
      position: 'insideLeft' as 'insideLeft',
      ...commonYAxisLabelProps
    },
    ...commonYAxisProps
  },
  {
    orientation: 'right' as 'right',
    yAxisId: 'right',
    reversed: true,
    label: {
      value: 'Zeefrest in (%)',
      position: 'insideRight' as 'insideRight',
      ...commonYAxisLabelProps
    },
    ...commonYAxisProps
  }
];

export type RestPercentage = Array<{ sieveSize: { size: number }, percentage: number }>;

const GradingCurveChart: React.FC<{ restPercentages: RestPercentage, gradingCurve?: GradingCurve, width?: number, height?: number }> = ({ restPercentages, gradingCurve, width, height }) => {

  let chartData = restPercentages.sort((a, b) => a.sieveSize.size - b.sieveSize.size).map(rp => {
    let minMax = {};
    if (gradingCurve && gradingCurve.sieveSteps) {
      const found = gradingCurve.sieveSteps.find(st => st.sieveSize.size === rp.sieveSize.size);
      if (found)
        minMax = { min: found.min, max: found.max, maxAlt: found.maxAlt };
    }
    if(minMax.hasOwnProperty('min'))
      return { size: rp.sieveSize.size, rest: Math.round(rp.percentage * 100) / 100, ...minMax }
    else
      return false
  });
  chartData=chartData.filter(Boolean);

  return (
    <LineChart data={chartData} margin={{ top: 20, right: 20, left: 20, bottom: 40 }} width={width || 680} height={height || 400} style={{ margin: '0 auto' }}>
      <XAxis dataKey="size" tick={{ fontSize: 13 }}>
        <Label value="Zeefopening (mm)" position="insideBottom" fontSize={14} offset={-20} />
      </XAxis>
      {yAxis.map((axis, k) => {
        const { label, ...yAxisProps } = axis;
        return <YAxis key={k} {...yAxisProps}><Label {...label} /></YAxis>;
      })}
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip formatter={(value) => value + '%'} labelFormatter={(value) => 'Zeefopening: ' + value + ' mm'} />
      <Legend verticalAlign="top" align="center" height={36} />
      {lines.map((lineProps, k) => {
        return <Line key={k} {...lineProps} />;
      })}
    </LineChart>
  )
}

export default GradingCurveChart;
