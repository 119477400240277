import { Recipe } from '../domain/types'
import { sanitizeResource, sanitizeGradingCurve } from '.'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (recipe: Recipe) {
  const { id, ingredients, gradingCurve, revisions, binderTotal, percentageFine, density, absorption, ...data } = recipe;
  (data as Recipe).ingredients = ingredients.map(({ resource, ...ingredient }) => ({ ...ingredient, resource: sanitizeResource(resource, false) }))
  gradingCurve && ((data as Recipe).gradingCurve = sanitizeGradingCurve(gradingCurve, false))
  return data
}
