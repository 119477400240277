import React, { useContext, useState, useRef, useCallback } from 'react';
import { Step } from './RecipeDialogContent';
import { Grid, makeStyles, Typography, Theme, Button, Box, DialogContent, DialogActions, ButtonBaseProps, Icon, ButtonBase, TextField } from '@material-ui/core';
import { StoreContext } from '../../StoreContextProvider';
import OptionButton from './OptionButton';
import moment from 'moment';
import { green, grey } from '@material-ui/core/colors';
import Dialog, { useDialog, DialogHeader } from '../Dialog';
import { DatePicker } from '@material-ui/pickers';
import { Attest } from '../../domain/types';
import { calculateChanges } from './bindersMixtureStep';

const useAddButtonStyles = makeStyles({
  root: {
    color: grey[600],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const AddButton: React.FC<ButtonBaseProps> = ({ children, ...props }) => {
  const classes = useAddButtonStyles();
  return <ButtonBase {...props} classes={classes}><Icon fontSize="large" color="inherit">add_circle</Icon><Typography color="inherit" variant="subtitle2">{children}</Typography></ButtonBase>
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(3)
  },
  greenButton: {
    background: green[600],
    color: theme.palette.getContrastText(green[600]),
    '&:hover': {
      background: green[700]
    }
  }
}));

const attestStep: Step = {
  title: 'Voeg een attest toe',
  hasErrors: recipe => !Boolean(recipe.attest),
  isCompleted: recipe => Boolean(recipe.attest),
  optional: true,
  optionalText: recipe => recipe.attest ? recipe.attest.attestNumber : undefined,
  StepComponent: ({ recipe, onChange }) => {
    const [attest, setAttest] = useState({} as { attestNumber?: string, expirationDate?: Date });
    const [newAttests, setNewAttests] = useState([] as Attest[]);
    const { attests } = useContext(StoreContext);
    const classes = useStyles();
    const { show, hide, getDialogProps } = useDialog();
    const inputRef = useRef() as React.RefObject<HTMLInputElement>;
    const allAttests = attests.length === 0 && newAttests.length === 0 && recipe.attest ? [recipe.attest] : [...attests, ...newAttests];

    const handleChange = useCallback((attest?: Attest) => {
      calculateChanges(recipe.ingredients, recipe.wbf, Boolean(attest));
      onChange({ ...recipe, attest });
    }, [recipe, onChange]);

    const handleAddAttest = useCallback((e: React.FormEvent) => {
      e.preventDefault();
      if (attest.attestNumber && attest.expirationDate) {
        setNewAttests([attest as Attest]);
        handleChange(attest as Attest);
        hide();
      }
    }, [attest, hide, handleChange]);

    return (
      <Box marginBottom={1}>
        <Grid container={true} wrap="wrap" spacing={2} className={classes.container}>
          {allAttests.length === 0 ? <Grid item={true}><Typography>Er zijn nog geen attesten</Typography></Grid> :
            allAttests.sort((a, b) => a.attestNumber.localeCompare(b.attestNumber)).map((attest, k) => (
              <OptionButton key={k} onClick={() => handleChange(attest)} selected={recipe.attest ? attest.attestNumber === recipe.attest.attestNumber : false}>
                <Typography variant="subtitle2">{attest.attestNumber}</Typography>
                <Typography variant="body2">{moment(attest.expirationDate).format('DD MMM YYYY')}</Typography>
              </OptionButton>
            ))}
          {recipe.attest && <OptionButton onClick={() => handleChange(undefined)}>
            <Typography variant="subtitle2">Geen attest<br />gebruiken</Typography>
          </OptionButton>}
          <Grid item={true}><AddButton onClick={() => show()}>Attest aanmaken</AddButton></Grid>
        </Grid>
        <Dialog {...getDialogProps()} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
          {(props: any) => (
            <form onSubmit={handleAddAttest}>
              <DialogHeader onClose={e => hide()}>Nieuw attest aanmaken</DialogHeader>
              <DialogContent>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Attest nummmer"
                  value={attest.attestNumber || ''}
                  onChange={e => setAttest({ ...attest, attestNumber: e.target.value })}
                  onFocus={() => inputRef.current && inputRef.current.select()}
                  fullWidth={true}
                  inputRef={inputRef}
                />
                <DatePicker
                  label="Verloop datum"
                  value={attest.expirationDate || null}
                  onChange={date => date && setAttest({ ...attest, expirationDate: date.toDate() })}
                  animateYearScrolling={true}
                  autoOk={true}
                  disablePast={true}
                  inputVariant="outlined"
                  margin="normal"
                  fullWidth={true}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" type="submit" disabled={!attest.attestNumber || !attest.expirationDate}>Opslaan&nbsp;&nbsp;<Typography variant="caption">(ENTER)</Typography></Button>
              </DialogActions>
            </form>
          )}
        </Dialog>
      </Box>
    )
  }
}

export default attestStep
