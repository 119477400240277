export const Settings ={
  environment: 'development', // 'development' or 'production'
  company: 'testcentraal',
  default_plant_id : 1,
  URLs: {
    server:'https://test.backend.jbmsoftware.nl',
    client:'',
    planningApp: {  // Central Planning App
      root: 'https://test.backend.jbmsoftware.nl/',
      recipeApp: {  // Embedded Recipe App
        root: 'https://test.backend.jbmsoftware.nl/recipe-app/',  // Recipe App Root URL
        loginSegment: 'post/login'
      }
    },
    labApp: 'https://mergetest.lab.jbmsoftware.nl/',
  }
}
