import React, {useRef, useState} from 'react'
import {TextField, Grid, MenuItem} from '@material-ui/core'
import {DosingMethod, Resource} from '../../types'
import useAuthorized from '../../useAuthorized'
import {getAll as getExcipientEffects} from "../../HTTPClients/RecipeApp/resources/excipientEffects";

export type ExcipientPropertiesProps = {
  resource: Resource,
  onChange: (resource: Resource) => void
}

const ExcipientProperties: React.FC<ExcipientPropertiesProps> = ({ resource, onChange }) => {
  const disabled = !useAuthorized(['update:resources_excipient'])
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [excEffects, setExcEffects] = useState([]);

  React.useEffect(() => {
    getExcipientEffects().then(
        function(response: any) {setExcEffects(response.data.data)}
    )
  },[]);

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <TextField
          label="Doseerwijze (receptuur)"
          value={resource.dosingMethod || DosingMethod.Percent}
          onChange={e => onChange({ ...resource, dosingMethod: e.target.value as DosingMethod })}
          variant="outlined"
          fullWidth={true}
          required={true}
          select={true}
          disabled={disabled}
          inputRef={firstInputRef}
        >
          <MenuItem>Geen</MenuItem>
          <MenuItem value={DosingMethod.Percent}>%</MenuItem>
          <MenuItem value={DosingMethod.Amount}>kg</MenuItem>
          <MenuItem value={DosingMethod.Liter}>liter</MenuItem>
        </TextField>
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          label="Opmerkingen"
          value={resource.remarks || ''}
          onChange={e => onChange({ ...resource, remarks: e.target.value })}
          variant="outlined"
          fullWidth={true}
          disabled={disabled}
        ></TextField>
      </Grid>
      <Grid item={true} lg={6}>
        <TextField
            label="Primair effect"
            value={resource.mainExcipientEffectId || '0'}
            onChange={e => onChange({ ...resource, mainExcipientEffectId: parseInt(e.target.value) })}
            variant="outlined"
            fullWidth={true}
            required={true}
            select={true}
            disabled={disabled}
        >
          <MenuItem value={0}>Geen</MenuItem>
          {excEffects.map( (excipientEffect) => {
            return <MenuItem key={excipientEffect.id} value={excipientEffect.id}>{excipientEffect.name}</MenuItem>
          })}
        </TextField>
      </Grid>
      <Grid item={true} lg={6}>
        <TextField
          label="Secundair effect"
          value={resource.secondaryExcipientEffectId || '0'}
          onChange={e => onChange({ ...resource, secondaryExcipientEffectId: parseInt(e.target.value) })}
          variant="outlined"
          fullWidth={true}
          select={true}
          disabled={disabled}
        >
          <MenuItem>Geen</MenuItem>
          {excEffects.map( (excipientEffect) => {
            return <MenuItem key={excipientEffect.id} value={excipientEffect.id}>{excipientEffect.name}</MenuItem>
          })}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default ExcipientProperties;
