import React from 'react';
import {Grid, Card, ListItem, ListItemAvatar, Avatar, ListItemText, DialogTitle, Divider, Dialog, DialogContent, DialogActions, Button} from '@material-ui/core';
import {ResourceType} from '../../types';
import resourceTypes from '../../resourceTypes';

const ResourceTypeSelector: React.FC<{ title: string, onSelect: (resourceType: ResourceType) => void, onClose: () => void }> = ({ title, onSelect, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent style={{backgroundColor: 'rgb(245, 245, 245)'}}>
      <Grid container={true} spacing={2}>
        {Object.keys(resourceTypes).map(k => {
          const resourceType = resourceTypes[k as ResourceType];
          return (
            <Grid item={true} key={k} xs={4}>
              <Card>
                <ListItem button={true} onClick={() => onSelect(k as ResourceType)}>
                  <ListItemAvatar>
                    <Avatar style={{ background: resourceType.color }}>
                      <img src={resourceType.icon} alt={resourceType.title} title={resourceType.title} style={{ width: 24, height: 24 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={resourceType.title} />
                </ListItem>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ position: 'relative' }}>
        <span style={{ flex: 1 }} />
        <Button onClick={onClose} color="secondary">Annuleren</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResourceTypeSelector;
