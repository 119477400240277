import React, { useContext, useCallback } from 'react';
import { Step } from './RecipeDialogContent';
import { Box, TextField, MenuItem } from '@material-ui/core';
import GradingCurveChart from './GradingCurveChart';
import { getMixtureCummulativeRestPercentages, getCummulativeRestPercentages } from '../../computed/recipeComputations';
import { ResourceType, SieveSetType, Addition, SieveTest } from '../../domain/types';
import { StoreContext } from '../../StoreContextProvider';

const gradingCurveStep: Step = {
  title: 'Ontwerpgebied',
  hasErrors: recipe => !Boolean(recipe.gradingCurve),
  isCompleted: recipe => Boolean(recipe.gradingCurve),
  optional: true,
  optionalText: recipe => recipe.gradingCurve ? recipe.gradingCurve.code : undefined,
  StepComponent: ({ recipe, onChange }) => {
    const { ingredients } = recipe;
    const { gradingCurves } = useContext(StoreContext);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const id = parseInt(e.target.value);
      const gradingCurve = gradingCurves.find(g => g.id === id);
      onChange({ ...recipe, gradingCurve });
    }, [onChange, recipe, gradingCurves]);

    const additionPercentages = ingredients.filter(r => r.resource.type === ResourceType.Addition).map(a => ({ resource: a.resource, amount: a.amount, restPercentages: (a.resource as Addition).sieveTest ? getCummulativeRestPercentages((a.resource as Addition).sieveTest as SieveTest) : [] }))
    const mixturePercentages = getMixtureCummulativeRestPercentages(additionPercentages);

    return (
      <Box marginTop={1} marginBottom={3} display="flex" flexDirection="column" alignItems="center">
        <TextField
          label="Zeeflijn"
          value={recipe.gradingCurve ? recipe.gradingCurve.id : ''}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          select={true}
          fullWidth={true}
        >
          <MenuItem>Geen</MenuItem>
          {gradingCurves.filter(g => g.type === SieveSetType.Mixture).map(({ id, code }, k) => <MenuItem key={k} value={id}>{code}</MenuItem>)}
        </TextField>
        <GradingCurveChart
          restPercentages={mixturePercentages}
          gradingCurve={recipe.gradingCurve || undefined}
        />
      </Box>
    )
  }
}

export default gradingCurveStep
