import React, {Fragment, useCallback} from "react";
import {Grid, InputAdornment, TextField} from "@material-ui/core";
import {Resource, ResourcePlant, ResourceType} from "../../../types";
import CurrencyTextField from "../../CurrencyTextField";
import NumericTextField from "../../NumericTextField";
import useAuthorized from "../../../useAuthorized";
import FillerProperties from "./FillerProperties";
import AdditionSieveTest from "./AdditionSieveTest";

type EditResourcePlantProps = {
  resource: Resource,
  resourcePlant: ResourcePlant,
  onChange: (resourcePlant: ResourcePlant) => void
}

const EditResourcePlant: React.FC<EditResourcePlantProps> = ({ resource, resourcePlant, onChange }) => {
  const disabled = !useAuthorized(['update:resources_info'])
  const priceHidden = !useAuthorized(['read:resources_price'])

  const onInputChange = useCallback((resourcePlant: ResourcePlant) => {
    onChange(resourcePlant)
  },[onChange]);

  // @ts-ignore
  return (
    <Fragment>
      <div className={'dialog-section next cascade'}>
        <h4>Grondstof informatie</h4>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <TextField
              label="Leverancier"
              value={resourcePlant.supplier || ''}
              onChange={e => onInputChange({ ...resourcePlant, supplier: e.target.value })}
              variant="outlined"
              fullWidth={true}
              disabled={disabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              label="Merknaam"
              value={resourcePlant.brand || ''}
              onChange={e => onInputChange({ ...resourcePlant, brand: e.target.value })}
              variant="outlined"
              fullWidth={true}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </div>

      {!priceHidden && <div className={'dialog-section next cascade'}>
        <h4>Grondstof prijs</h4>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={4}>
            <CurrencyTextField
              label="Basisprijs (p/t)"
              value={resourcePlant.price || null }
              onChange={(e: any) => onInputChange({ ...resourcePlant, price: e.target.value })}
              fullWidth={true}
              required={true}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </div>}

      <div className={'dialog-section next cascade'}>
        <h4>Grondstof eigenschappen</h4>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Volumieke massa"
              value={resourcePlant.density}
              onChange={(e: any) => onInputChange({ ...resourcePlant, density: e.target.value })}
              fullWidth={true}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg/m<sup>3</sup></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Chloridegehalte"
              value={typeof resourcePlant.chloridePercentage !== 'undefined' && resourcePlant.chloridePercentage !== null ? resourcePlant.chloridePercentage : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, chloridePercentage: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={6}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Alkali gehalte"
              value={typeof resourcePlant.alkaliPercentage !== 'undefined' && resourcePlant.alkaliPercentage !== null ? resourcePlant.alkaliPercentage : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, alkaliPercentage: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={6}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </div>
      {resource.type===ResourceType.Cement && <div className={'dialog-section next cascade'}>
        <h4>Cement eigenschappen</h4>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="C-waarde"
              value={typeof resourcePlant.cvalue !== 'undefined' && resourcePlant.cvalue !== null ? resourcePlant.cvalue : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, cvalue: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={2}
              required={false}
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Sterkte 7 dagen"
              value={typeof resourcePlant.strengthWeek !== 'undefined' && resourcePlant.strengthWeek !== null ? resourcePlant.strengthWeek : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, strengthWeek: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={6}
              InputProps={{
                endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
              }}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Sterkte 28 dagen"
              helperText="(Normwaarde)"
              value={typeof resourcePlant.strengthNorm !== 'undefined' && resourcePlant.strengthNorm !== null ? resourcePlant.strengthNorm : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, strengthNorm: e.target.value })}
              fullWidth={true}
              required={true}
              maximumFractionDigits={6}
              InputProps={{
                endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </div>}
      {resource.type===ResourceType.Filler && <div className={'dialog-section next cascade'}>
        <h4>Vulstof eigenschappen</h4>
        <FillerProperties resourcePlant={resourcePlant} onResourcePlantChange={onInputChange}></FillerProperties>
      </div>}
      <div className={'dialog-section next cascade'}>
        {resource.type===ResourceType.Excipient && <h4>Hulpstof eigenschappen</h4>}
        {resource.type===ResourceType.Addition && <h4>Toeslag eigenschappen</h4>}
        {(resource.type===ResourceType.Excipient || resource.type===ResourceType.Addition) &&
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={6}>
              <NumericTextField
                label="Vochtgehalte (incl. absorptie)"
                value={typeof resourcePlant.moisture !== 'undefined' && resourcePlant.moisture !== null ? resourcePlant.moisture : ''}
                onChange={(e: any) => onInputChange({ ...resourcePlant, moisture: e.target.value })}
                fullWidth={true}
                maximumFractionDigits={1}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <NumericTextField
                label="Absorptie"
                value={typeof resourcePlant.absorption !== 'undefined' && resourcePlant.absorption !== null ? resourcePlant.absorption : ''}
                onChange={(e: any) => onInputChange({ ...resourcePlant, absorption: e.target.value })}
                fullWidth={true}
                maximumFractionDigits={1}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>}
      </div>
      {resource.type===ResourceType.Addition && <div className={'dialog-section next cascade'}>
        <h4>Zeefanalyse</h4>
        <AdditionSieveTest resourcePlant={resourcePlant} resource={resource} onResourcePlantChange={onInputChange}></AdditionSieveTest>
      </div>}
      {resource.type===ResourceType.Extra && <div className={'dialog-section next cascade'}>
        <h4>Extra eigenschappen</h4>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Percentage fijn"
              value={typeof resourcePlant.percentageFine !== 'undefined' && resourcePlant.percentageFine !== null ? resourcePlant.percentageFine : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, percentageFine: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={3}
              disabled={disabled}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Vochtgehalte (Incl. absorptie)"
              value={typeof resourcePlant.moisture !== 'undefined' && resourcePlant.moisture !== null ? resourcePlant.moisture : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, moisture: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={1}
              disabled={disabled}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <NumericTextField
              label="Absorptie"
              value={typeof resourcePlant.absorption !== 'undefined' && resourcePlant.absorption !== null ? resourcePlant.absorption : ''}
              onChange={(e: any) => onInputChange({ ...resourcePlant, absorption: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={1}
              disabled={disabled}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </div>}
    </Fragment>
  )
}

export default EditResourcePlant;
