import React, {ReactNode} from 'react';
import OptionButtonsContainer, { OptionButtonsGroup } from './OptionButtonsContainer';
import { Box, Grid, Icon } from '@material-ui/core';
import { ResourceUnion } from '../../domain/types';
import { grey } from '@material-ui/core/colors';
import OptionButton from './OptionButton';

const CenteredArrow: React.FC<{ width?: number }> = ({ width }) => <Box flex={width ? 'none' : 1} width={width ? width + '%' : ''} display="flex" alignItems="center" justifyContent="center"><Icon fontSize="small" style={{ color: grey[400] }}>arrow_upward</Icon></Box>

type ToggleResourceOptionButtonsProps = {
  loading: boolean
  groups: Array<Omit<OptionButtonsGroup, 'getOptionContent'>>
  onToggle: (option: ResourceUnion) => void
  children?: ReactNode
}

const ToggleResourceOptionButtons: React.FC<ToggleResourceOptionButtonsProps> = ({ loading, children, groups, onToggle }) => {

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {children}
      <Grid container={true}>
        {groups.map(({ width }, k) => <CenteredArrow key={k} width={width} />)}
      </Grid>
      <OptionButtonsContainer
        loading={loading}
        groups={groups.map(group => ({
          ...group,
          getOptionContent: (resource: ResourceUnion) => (
            <OptionButton onClick={() => onToggle(resource)}>{resource.name}</OptionButton>
          ),
        }))}
      />
    </Box>
  )
}

export default ToggleResourceOptionButtons;
